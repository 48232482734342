.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #282c34;
}
.custom-container {
  width: 500px; /* Özel genişlik değeri */
  height: 400px; /* Özel yükseklik değeri */
  margin: 0 auto; /* Merkezlemek için kullanabilirsiniz */
  /* Diğer stil özellikleri */
}

.login-container {
  background-image: url(img/sky.png); /* Bulutlu resminizin yolunu belirtin */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh; /* Sayfanın yüksekliğine uyacak şekilde ayarlayın */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* timeout olan satırların arka plan rengi */
.row-timeout {
  background-color: #ffcccc; /* Kırmızımsı */
}

/* reject (red) olan satırların arka plan rengi */
.row-reject {
  background-color: #ffe6e6; /* Hafif kırmızı */
}

/* approved (onaylanmış) satırların arka plan rengi */
.row-approved {
  background-color: #ccffcc; /* Yeşil */
}

/* Dark tema için özel stil */
.dark-theme {
  background-color: #141414;
  color: white;
}

.dark-theme .ant-layout {
  background-color: #141414;
}

.dark-theme .ant-card {
  background-color: #1f1f1f;
}

.dark-theme .ant-menu-dark {
  background-color: #1f1f1f;
}

.custom-row {
  font-size: 14px; /* Satırdaki yazı boyutunu küçültüyoruz */
}

/* Dark theme için Tree bileşeni stilleri */
.custom-dark-tree.ant-tree {
    background-color: transparent;
}

.custom-dark-tree .ant-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.65);
}

.custom-dark-tree .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.custom-dark-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #1890ff;
    color: white;
}

.custom-dark-tree .ant-tree-switcher {
    color: rgba(255, 255, 255, 0.65);
}

.custom-dark-tree .ant-tree-title {
    color: rgba(255, 255, 255, 0.65);
}

/* Search input için dark tema stilleri */
.ant-input-search .ant-input {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.65);
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
    border-color: #1890ff;
    background-color: rgba(255, 255, 255, 0.08);
}

.ant-input-search .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.45);
}

.ant-input-search .ant-input-group-addon {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.15);
}

.ant-input-search .ant-input-search-button {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.15);
}

/* Sider stilleri */
.ant-layout-sider {
    transition: width 0.2s !important;
}

/* Sider trigger (açma/kapama butonu) stilleri */
.ant-layout-sider-trigger {
    position: fixed !important;
    right: 0;
    transform: translateX(100%);
    z-index: 1;
}

/* Collapsed durumunda Sider stilleri */
.ant-layout-sider.ant-layout-sider-collapsed {
    overflow: visible !important;
}

/* Sider içerik geçiş animasyonu */
.ant-layout-sider-children {
    transition: opacity 0.2s;
}

/* Özel Sider stilleri */
.custom-sider.ant-layout-sider-collapsed {
    min-width: 0 !important;
    width: 0 !important;
}

.custom-sider .ant-layout-sider-trigger {
    position: fixed !important;
    left: 0;
    width: 36px !important; /* Trigger butonunun genişliği */
    transform: translateX(0);
    transition: all 0.2s;
}

.custom-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    left: 0;
}

/* Tree stilleri aynı kalacak */
.custom-dark-tree.ant-tree {
    background-color: transparent;
}

.dashboard-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

.dashboard-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dashboard-card .ant-card-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 16px;
}

.dashboard-card .ant-list-item {
  padding: 12px;
  margin: 8px 0;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.dashboard-card .ant-list-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}


